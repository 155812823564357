body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 21px;
  text-align: center; }

body {
  background: #F7F7F7; }

a {
  color: #0a1b4f; }

.btn {
  font-size: 14px;
  color: #FFF;
  background-color: #0a1b4f;
  padding: 4px 30px 4px 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0 10px; }
  .btn:hover {
    cursor: pointer; }
  .btn:disabled {
    cursor: progress; }

.form-input {
  border-radius: 3px;
  border: 1px dotted #d0d9ee;
  padding: 5px;
  font-size: 16px; }

h1 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: #56cdff;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 5px; }

#top-bar {
  background: #0a1b4f; }
  #top-bar .top-bar-inner {
    max-width: 950px;
    padding: 5px 10px;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto; }
    @media screen and (min-width: 500px) {
      #top-bar .top-bar-inner {
        padding: 5px 20px; } }

.logo-container {
  text-align: left;
  padding: 40px 0;
  position: relative; }

#logo {
  font-size: 28.8px;
  color: #0a1b4f;
  font-weight: bold;
  line-height: 1; }
  @media screen and (min-width: 500px) {
    #logo {
      font-size: 48px; } }
  #logo a {
    color: #0a1b4f;
    font-weight: bold;
    text-decoration: none; }

#latitud-interior {
  font-size: 16.2px;
  color: #57c7ff;
  line-height: 1;
  font-weight: 500; }
  @media screen and (min-width: 500px) {
    #latitud-interior {
      font-size: 27px; } }

#nav {
  font-size: 14px;
  font-weight: bold;
  text-align: left; }
  #nav .nav-interior {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px; }
    @media screen and (max-width: 990px) {
      #nav .nav-interior {
        display: block;
        margin-right: 0; } }
    #nav .nav-interior a {
      text-decoration: none;
      padding-bottom: 4px;
      border-bottom: 3px solid #0a1b4f;
      display: block;
      text-transform: uppercase; }
  @media screen and (max-width: 990px) {
    #nav.hide {
      display: none; } }

.main {
  background: #fff;
  max-width: 950px;
  padding: 0 10px 40px 10px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 500px) {
    .main {
      padding: 0 20px 40px 20px; } }
  @media screen and (min-width: 768px) {
    .main {
      padding: 0 60px 40px 60px; } }

.intro {
  padding: 0 0 20px 0;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold; }

.ginesta-app {
  text-align: center;
  margin-bottom: 40px; }
  .ginesta-app__shell {
    background: #f2f4f9;
    padding: 20px 10px;
    box-sizing: border-box; }
    @media screen and (min-width: 500px) {
      .ginesta-app__shell {
        padding: 20px; } }
  .ginesta-app .menu-toggle {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    padding: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
    @media screen and (min-width: 990px) {
      .ginesta-app .menu-toggle {
        display: none; } }
  .ginesta-app__status-message {
    background: #d0d9ee;
    padding: 20px;
    font-weight: bold;
    border-radius: 3px;
    font-size: 1.1em;
    transition: all 0.2s linear;
    margin-bottom: 30px; }
    .ginesta-app__status-message.waiting {
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-name: progress;
              animation-name: progress;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-direction: normal;
              animation-direction: normal;
      color: #fff; }
    .ginesta-app__status-message.error {
      background-color: #CC0000;
      color: #fff; }
    .ginesta-app__status-message.success {
      background: #109648;
      color: #fff; }
  .ginesta-app .form-row {
    margin-top: 10px; }
    .ginesta-app .form-row label {
      font-weight: bold;
      margin-right: 10px; }
  .ginesta-app .form-section {
    margin-top: 20px; }
  .ginesta-app .btn {
    margin-top: 20px; }
    .ginesta-app .btn:disabled,
    .ginesta-app .btn button[disabled] {
      background-color: #999; }
  .ginesta-app .icon-check {
    max-width: 150px;
    height: auto;
    margin-top: 40px; }
  .ginesta-app .language-switcher {
    text-align: right; }
    .ginesta-app .language-switcher a {
      display: inline-block;
      margin-left: 10px; }

.usages__single {
  border-radius: 3px;
  border: 1px dotted #d0d9ee;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  padding: 0 20px;
  background: #fff;
  margin-top: 20px;
  text-align: left; }
  .usages__single p {
    margin-top: 0;
    margin-bottom: 1em;
    font-weight: normal; }
  .usages__single__renewal label {
    display: block;
    margin-bottom: 10px; }
  .usages__single__renewal .radio-label-text {
    margin-right: 5px; }

.usages .col {
  width: 33%;
  display: inline-block;
  vertical-align: top;
  padding: 20px 0 10px 0; }

.usages .label {
  font-weight: bold; }

.usages sup {
  line-height: 1; }

.usages .btn-submit {
  margin-top: 20px; }

.usages #user-iban {
  min-width: 50%; }

#footer {
  background: #0a1b4f;
  padding: 40px 20px;
  color: #fff; }
  #footer a {
    color: #fff; }
  #footer #logos img {
    margin: 0 10px; }
  #footer #nav-bottom {
    padding: 20px 0; }
    #footer #nav-bottom > div {
      display: inline-block;
      margin: 0 5px; }
  #footer #address {
    padding-bottom: 20px; }

@-webkit-keyframes progress {
  0%, 100% {
    background-color: #109648; }
  50% {
    background-color: #12ad53; } }

@keyframes progress {
  0%, 100% {
    background-color: #109648; }
  50% {
    background-color: #12ad53; } }

