$primary: #0a1b4f;
$success: #109648;
$background: #F7F7F7;
$light-grey: #f2f4f9;
$nav-breakpoint: 990px;
$screen-sm: 500px;

html, body {
	color: #000000;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	line-height: 21px;
	text-align: center;
}

body {
	background: $background;
}

a {
    color: $primary;
}

.btn {
    font-size: 14px;
    color: #FFF;
    background-color: #0a1b4f;
    padding: 4px 30px 4px 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin: 0 10px;

    &:hover {
    	cursor: pointer;
    }

    &:disabled {
    	cursor: progress;
    }
}

.form-input {
    border-radius: 3px;
    border: 1px dotted #d0d9ee;
    padding: 5px;
    font-size: 16px;
}

h1 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #56cdff;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 5px;
}

.container {

}

#top-bar {
	background: $primary;

	.top-bar-inner {
		max-width: 950px;
		padding: 5px 10px;
		box-sizing: border-box;
		width: 100%;
		margin: 0 auto;

		@media screen and (min-width: $screen-sm) {
			padding: 5px 20px;
		}
	}
}

.logo-container {
	text-align: left;
	padding: 40px 0;
	position: relative;
}

#logo {
	font-size: 48px * 0.6;
	color: #0a1b4f;
	font-weight: bold;
	line-height: 1;

	@media screen and (min-width: $screen-sm) {
		font-size: 48px;
	}

	a {
		color: #0a1b4f;
	    font-weight: bold;
	    text-decoration: none;
	}
}

#latitud-interior {
    font-size: 27px * 0.6;
    color: #57c7ff;
    line-height: 1;
    font-weight: 500;

    @media screen and (min-width: $screen-sm) {
		font-size: 27px;
	}
}

#nav {
	font-size: 14px;
	font-weight: bold;
	text-align: left;

	.nav-interior {
		position: relative;
	    display: inline-block;
	    margin-right: 20px;
	    margin-bottom: 10px;

	    @media screen and (max-width: $nav-breakpoint) {
	    	display: block;
	    	margin-right: 0;
	    }

	    a {
	    	text-decoration: none;
	    	padding-bottom: 4px;
	    	border-bottom: 3px solid #0a1b4f;
	    	display: block;
	    	text-transform: uppercase;
	    }
	}

	@media screen and (max-width: $nav-breakpoint) {
		&.hide {
			display: none;
		}
	}
}

.main {
	background: #fff;
	max-width: 950px;
	padding: 0 10px 40px 10px;
	box-sizing: border-box;
	width: 100%;
	margin: 0 auto;

	@media screen and (min-width: $screen-sm) {
		padding: 0 20px 40px 20px;
	}

	@media screen and (min-width: 768px) {
		padding: 0 60px 40px 60px;
	}
}

.intro {
	padding: 0 0 20px 0;
	text-align: left;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.ginesta-app {
	text-align: center;
	margin-bottom: 40px;

	&__shell {
		background: $light-grey;
		padding: 20px 10px;
		box-sizing: border-box;

		@media screen and (min-width: $screen-sm) {
			padding: 20px;
		}

	}

	.menu-toggle {
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		padding: 0;
		transform: translate(0, -50%);
		background: none;
		border: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		@media screen and (min-width: $nav-breakpoint) {
			display: none;
		}
	}
	
	&__status-message {
		background: #d0d9ee;
		padding: 20px;
		font-weight: bold;
		border-radius: 3px;
		font-size: 1.1em;
		transition: all 0.2s linear;
		margin-bottom: 30px;

		&.waiting {
			// background: darken(#d0d9ee, 10%);
			animation-duration: 1s;
			animation-name: progress;
			animation-iteration-count: infinite;
			animation-direction: normal; 
			color: #fff;
		}

		&.error {
			background-color: #CC0000;
			color: #fff;
		}

		&.success {
			background: $success;
			color: #fff;
		}
	}

	.form-row {
		margin-top: 10px;

		label {
			font-weight: bold;
			margin-right: 10px;
		}
	}

	.form-section {
		margin-top: 20px;
	}

	.btn {
		margin-top: 20px;

		&:disabled,
		button[disabled] {
			background-color: #999;
		}
	}

	.icon-check {
		max-width: 150px;
		height: auto;
		margin-top: 40px;
	}

	.language-switcher {
		text-align: right;

		a {
			display: inline-block;
			margin-left: 10px;
		}
	}
}

.usages {
	&__single {
		border-radius: 3px;
	    border: 1px dotted #d0d9ee;
	    -moz-border-radius: 3px;
	    -webkit-border-radius: 3px;
	    -khtml-border-radius: 3px;
	    padding: 0 20px;
	    background: #fff;
	    margin-top: 20px;
	    text-align: left;

	    p {
	    	margin-top: 0;
	    	margin-bottom: 1em;
	    	font-weight: normal;
	    }

	    &__name {
	    
	    }

	    &__date {

	    }

	    &__renewal {
	    	// border-left: 1px dotted #d0d9ee; 

	    	label {
	    		display: block;
	    		margin-bottom: 10px;
	    	}

	    	.radio-label-text {
	    	
	    		margin-right: 5px;
	    	}
	    }
	}

	.col {
		width: 33%;
		display: inline-block;
		vertical-align: top;
		padding: 20px 0 10px 0;
	}

	.label {
		font-weight: bold;
	}

	sup {
		line-height: 1;
	}

	.btn-submit {
		margin-top: 20px;
	}

	#user-iban {
		min-width: 50%;
	}
}

// Footer
#footer {
	background: $primary;
	padding: 40px 20px;
	color: #fff;

	a {
		color: #fff;
	}

	#logos {
		img {
			margin: 0 10px;
		}
	}

	#nav-bottom {
		padding: 20px 0;

		> div {
			display: inline-block;
			margin: 0 5px;
		}
	}

	#address {
		padding-bottom: 20px;
	}
}

@keyframes progress {
	0%, 100% {
		background-color: $success;
	}

	50% {
		background-color: lighten($success, 5%);
	}
}